import * as React from "react";
import { Link } from "gatsby";

export default function Component() {
  return (
    <main>
      <h1>页面未找到</h1>
      <p>您请求的页面不存在。</p>
      <Link to="/">返回首页</Link>
    </main>
  );
}
